<template>
  <div class="container">
    {{ selectedDisciplines }}
    <DataTable v-model:filters="filters" :value="educationDisciplines" paginator :rows="10"
               filterDisplay="menu" :globalFilterFields="['discipline_name', 'semester']">
      <template #empty> Ничего не найдено...</template>
      <template #loading> Пожалуйста подождите...</template>
      <template #header>
        <div class="d-flex justify-content-between">
            <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Название или семестер..."></InputText>
            </span>

          <Button label="Сформировать" icon="pi pi-folder" @click="chooseMiner"/>
        </div>
      </template>

      <Column field="formation_education_program_id" header="ID"></Column>
      <Column field="discipline_name" header="Название дисциплины"></Column>
      <Column field="semester" header="Семестер"></Column>
      <Column field="credit" header="Кредиты"></Column>
      <Column header="Действие">
        <template #body="{data}">
          <Checkbox v-model="selectedDisciplines.formation_ids" :value=data.formation_education_program_id></Checkbox>
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="visible_modal" modal header="Выбранные дисциплины" :style="{ width: '50vw' }">
      <p v-for="(selectedDiscipline, index) in selectedDisciplines.formation_ids" :key="index">
        {{
          educationDisciplines.filter(i => i.formation_education_program_id === selectedDiscipline)[0].discipline_name
        }}
      </p>
      <Button icon="pi pi-check" label="Создать" class="float-end" @click="createMiner(selectedDisciplines)"/>
    </Dialog>
  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";
import {FilterMatchMode, FilterOperator} from "primevue/api";

export default {
  name: "EducationProgramMiner",
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_speciality_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'education_speciality_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'admission_year': {value: null, matchMode: FilterMatchMode.EQUALS},
        'language.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      selectedDisciplines: {
        education_program_id: this.$route.query.education_program_id,
        decree_goso_template_id: this.$route.query.decree_goso_template_id,
        minor_education_program_id: this.$route.query.id_minor,
        formation_ids: []
      },
      visible_modal: false
    }
  },
  computed: {
    ...mapState('educationProgramMinor', ['educationDisciplines']),
  },
  methods: {
    ...mapActions('educationProgramMinor', ['GET_EDUCATION_DISCIPLINES']),
    ...mapActions('educationProgramMinor', ['POST_CREATE_MINOR']),

    async chooseMiner() {
      if (this.selectedDisciplines.formation_ids.length === 0) {
        this.$error({title: "Вы не выбрали дисциплины!"})
      } else {
        this.visible_modal = true
      }
    },

    async createMiner(selectedDisciplines) {
      if (await this.POST_CREATE_MINOR(selectedDisciplines)) {
        this.$message({title: "Майнер успешно создан!"})
        this.visible_modal = false
      }
    }
  },
  async mounted() {
    await this.GET_EDUCATION_DISCIPLINES(this.$route.query['id_minor'])
  }
}
</script>

<style scoped>
</style>